import * as React from "react"
import Circle from "../assets/image/about/faded-circle.svg"
import CenterImage from "../assets/image/about/about.png"
import Trainer from "../assets/image/about/trainee.svg"
import Courses from "../assets/image/about/course.svg"
import Learning from "../assets/image/about/learning.svg"
import Talent from "../assets/image/about/about-2.png"
import One from "../assets/image/about/1.svg"
import Two from "../assets/image/about/2.svg"
import Three from "../assets/image/about/3.svg"
import Four from "../assets/image/about/4.svg"
import Journey1 from "../assets/image/journey1.png"
import Dot from "../assets/image/about/dot.svg"
import Layout from "../components/layout"
import { MultiLangBody, MultiLangField } from "skillstrainer-resource-library"

const Info = ({ pageContext }) => {
  return (
    <MultiLangBody _key={pageContext.key}>
      <Layout>
        <div className="about">
          <div className="pt-52 text-justify lg:text-left text text-lg overflow-hidden content mx-auto">
            <div className="text-4xl max-w-max mx-auto font-semibold relative text-center px-10 lg:px-0">
              <img src={Circle} className="absolute -top-28 -left-32" />
              <p className="orange-light1">
                <MultiLangField name={"head1"}>
                  We Are India’s First Exclusive
                </MultiLangField>
              </p>

              <p className="green-dark">
                <MultiLangField name={"head2"}>
                  Vocational Skilling Digital Platform
                </MultiLangField>
              </p>
            </div>
            <div className="p-4 rounded-3xl bg-white shadow-lg my-20 px-10 lg:px-0">
              <div>
                <img src={CenterImage} />
              </div>
            </div>
            <div className="text-base bg-dream w-full xl:mx-0 xl:w-4/5 xl:mx-auto px-5 sm:px-20 pt-6 sm:pt-12 pb-24 relative">
              <p>
                <MultiLangField name={"Description"}>
                  With a dream to offer quality skilling to anyone, anytime and
                  anywhere, SkillsTrainer.in has been launched by Unifiers
                  Social Ventures Pvt Ltd, India's leading vocational skilling
                  organisation and a NSDC partner. In the last few years of our
                  operations, Unifiers projects have impacted over 2 million
                  trainees across manufacturing and service sector. To know more
                  about our work logon to:
                </MultiLangField>
              </p>

              <p className="red-dark mt-12">www.unifiers.in</p>
              <img
                src={Circle}
                className="absolute -bottom-24 -right-28 transform rotate-180"
              />
              <div className="bg-red-dark h-1 w-28 mb-32"></div>
              <div className="flex items-center flex-wrap justify-center md:justify-between md:absolute -bottom-24 left-0 right-0 sm:px-10 2xl:px-32">
                <div className="about-box blue-dark2">
                  <img src={Trainer} className="mt-3 mb-4" />
                  <p className="text-3xl font-semibold my-3">
                    <MultiLangField name={"student"}>20,00,000+</MultiLangField>
                  </p>
                  <p>
                    <MultiLangField name={"student_impacted"}>
                      Trainees impacted
                    </MultiLangField>
                  </p>
                </div>
                <div className="about-box blue-dark2">
                  <img src={Courses} className="mt-3 mb-4" />
                  <p className="text-3xl font-semibold my-3">
                    <MultiLangField name={"no_of_courses"}>200+</MultiLangField>
                  </p>
                  <p>
                    <MultiLangField name={"courses_types"}>
                      Digital Courses, Trainees and Trainer Manuals
                    </MultiLangField>
                  </p>
                </div>
                <div className="about-box blue-dark2">
                  <img src={Learning} className="mt-3 mb-4" />
                  <p className="text-3xl font-semibold my-3">
                    <MultiLangField name={"no_of_hours"}>
                      1000+ Hours
                    </MultiLangField>
                  </p>
                  <p>
                    <MultiLangField name={"learning_types"}>
                      Digital Learning Content
                    </MultiLangField>
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-blue-grad mt-10 md:mt-52 my-12 relative">
              <img src={Dot} className="absolute bottom-5 right-10" />

              <div className="p-6 sm:py-12 pb-10 sm:px-10">
                <p className="blue-dark2 text-3xl font-semibold">
                  <MultiLangField name={"skill_benefit"}>
                    {" "}
                    Acquire Skills & Hire Talent{" "}
                  </MultiLangField>
                </p>
                <div className="flex items-center flex-wrap text-3xl font-semibold mt-2">
                  <p className="red-dark">
                    <MultiLangField name={"skill_place"}>
                      Anywhere
                    </MultiLangField>
                    ​
                  </p>
                  <p className="blue-dark2">
                    <MultiLangField name={""}>&nbsp;&amp;</MultiLangField>
                  </p>
                  <p className="green-light">
                    <MultiLangField name={"skill_time"}>
                      &nbsp;Anytime
                    </MultiLangField>
                  </p>
                </div>
                <div className="p-4 bg-white rounded-2xl w-full lg:w-4/5 mt-10 relative">
                  <div className="bg-white p-4 rounded-lg absolute top-full lg:top-1/3 line-text talent-content left-1/2 lg:left-full transform -translate-x-1/2 mt-10 lg:mt-0">
                    <p className="orange-light1 text-xl font-semibold">
                      <MultiLangField name={"digital_skilling_course"}>
                        {" "}
                        Digital Skilling Courses for 25% + global population
                      </MultiLangField>
                    </p>
                    <div className="mt-4 text-sm">
                      <div className="flex items-center">
                        <div className="h-2 w-2 bg-orange-light1 rounded-full"></div>
                        <p className="ml-3">
                          <MultiLangField name={"modular_courses"}>
                            NSQF mapped/ Modular courses
                          </MultiLangField>
                        </p>
                      </div>
                      <div className="flex items-center mt-1">
                        <div className="h-2 w-2 bg-orange-light1 rounded-full"></div>
                        <p className="ml-3">
                          <MultiLangField name={"course_offering"}>
                            B2B, B2G, B2C Training/ Course offerings
                          </MultiLangField>
                        </p>
                      </div>
                    </div>
                  </div>
                  <img src={Talent} className="w-full" />
                </div>
              </div>
            </div>
            <div className="mt-60 mb-20 lg:my-20 mx-auto max-w-max">
              <p className="blue-dark2 text-3xl font-semibold text-center">
                <MultiLangField name={"Impact"}>
                  {" "}
                  Where We Stand Today...
                </MultiLangField>
              </p>
              <div className="grid grid-cols-1 xl:grid-cols-3 mx-auto leading-0 text-sm mt-6">
                <div className="flex flex-col sm:flex-row xl:flex-col">
                  <div className="secondary-card border-blue">
                    <img src={One} />
                    <p className="mt-12 text-md">
                      <MultiLangField name={"understading_of_society"}>
                        Deep understanding of the Indian and Global vocational
                        skilling space and sound Industry, Government and
                        Academia relationships
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="secondary-card border-green">
                    <img src={Two} />
                    <p className="mt-12 text-md">
                      <MultiLangField name={"largest_virtual_skilling"}>
                        Launched world's largest NSQF based Virtual Skilling
                        mandate to Digitally train 100,000 Trainees and 5000
                        Trainers
                      </MultiLangField>
                    </p>
                  </div>
                </div>

                <div className="secondary-card border-orange">
                  <div className="flex items-center">
                    <img src={Three} />
                    <p className="ml-8">
                      <MultiLangField name={"owner"}>
                        Largest owner of
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="flex items-start mt-20 px-4">
                    <div className="h-2 w-2  bg-orange-light1 rounded-full">
                      &nbsp;&nbsp;
                    </div>
                    <p className="ml-6 -mt-2">
                      <MultiLangField name={"mapped_courses"}>
                        NSQF mapped vocational skilling courses IPR in India
                        120+ courses across 10+ sectors including the top 10
                        employment generating Job Roles​
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="flex items-start mt-12 px-4">
                    <div className="h-2 w-2  bg-orange-light1 rounded-full">
                      &nbsp;&nbsp;
                    </div>
                    <p className="ml-6 -mt-2">
                      <MultiLangField name={"digital_vocational"}>
                        Digital Vocational Skilling Courses - 75+ digital
                        courses covering over 50% of India’s organized sector
                        employment​
                      </MultiLangField>
                    </p>
                  </div>
                </div>
                <div className="secondary-card border-yellow">
                  <div className="flex items-center">
                    <img src={Four} />
                    <p className="ml-8">
                      <MultiLangField name={"first_comapny"}>
                        First company in India to
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="flex items-start mt-20 px-4">
                    <div className="h-2 w-2 bg-orange-light2 rounded-full">
                      &nbsp;&nbsp;
                    </div>
                    <p className="ml-6 -mt-2">
                      <MultiLangField name={"national_occupational"}>
                        {" "}
                        Write a National Occupational Standard (NOS)​
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="flex items-start my-8 xl:my-16 px-4">
                    <div className="h-2 w-2 bg-orange-light2 rounded-full">
                      &nbsp;&nbsp;
                    </div>
                    <p className="ml-6 -mt-2">
                      <MultiLangField name={"courses_for_all"}>
                        {" "}
                        To launch a vocational skilling LMS to offer courses for
                        trainees, trainers & assessors
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="flex items-start mt-8 xl:mt-12 px-4 mb-8">
                    <div className="h-2 w-2 bg-orange-light2 rounded-full">
                      &nbsp;&nbsp;
                    </div>
                    <p className="ml-6 -mt-2">
                      <MultiLangField name={"differently_abled"}>
                        To offer digital skilling courses for the differently
                        abled​
                      </MultiLangField>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
            <p className="blue-dark2 text-5xl font-semibold text-center">
              Meet Our <span className="red-dark">Team</span>
            </p>
            <div className="flex flex-wrap my-16 justify-center">
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </Layout>
    </MultiLangBody>
  )
}

export default Info
